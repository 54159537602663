import React from 'react';
import Section from '@atoms/Section/Section';
import Title from '@atoms/Title/Title';
import TitleGroup from '@atoms/TitleGroup/TitleGroup';
import MarkdownText from '@atoms/MarkdownText/MarkdownText';
import Module from '@atoms/Module/Module';
import './OnlineOrOnSite.scss';
import CardGrid from '../../CardGrid/CardGrid';
import { Box } from '@material-ui/core';
import GenericIcon from '../GenericIcon';
import Icon from '@atoms/Icon/Icon';

const OnlineOrOnSite = ({ data }) => {
    const { title, cards } = data;
    return (
        <div className="OnlineOrOnSite">
            <Section container="large" spacingTop="m" spacingBottom="l">
                <Section spacingBottom="s">
                    <TitleGroup align="desktopCenter" title={title} />
                </Section>
                <CardGrid size="2">
                    {cards.map((item: any) => (
                        <Module radius="l">
                            <Box display="flex" alignItems="flex-start">
                                <Box sx={{ mr: 2 }}>
                                    <Icon
                                        icon={<GenericIcon icon={item.icon} />}
                                        size="m"
                                        theme="pink"
                                    />
                                </Box>
                                <Title size="l">{item.title}</Title>
                            </Box>
                            <MarkdownText content={item.content} />
                        </Module>
                    ))}
                </CardGrid>
            </Section>
        </div>
    );
};

export default OnlineOrOnSite;
